import {ActionIcon, Button, Checkbox, createTheme, Input, MultiSelect, Overlay, Select, Switch, TextInput, type MantineThemeColors} from '@mantine/core';
import colors from 'tailwindcss/colors';
import ButtonClassNames from '#/app/theme/Button.module.css';
import InputClassNames from '#/app/theme/Input.module.css';
import OverlayClassNames from '#/app/theme/Overlay.module.css';

const fontFamily =
  'Roboto,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';

const tailwindColors = Object.fromEntries(Object.entries(colors)
  .filter(([, value]) => typeof value === 'object')
  .map(([key, value]) => ['' + key, Object.values(value).slice(0, -1)])) as unknown as MantineThemeColors;

export const theme = createTheme({
  breakpoints: {
    '2xl': '96rem',
    lg: '64rem',
    md: '48rem',
    sm: '40rem',
    xl: '80em',
  },
  colors: {
    ...tailwindColors,
    dark: tailwindColors.slate,
    grape: tailwindColors.fuchsia,
    gray: tailwindColors.slate,
  },
  components: {
    ActionIcon: ActionIcon.extend({
      defaultProps: {size: 40},
    }),
    Button: Button.extend({
      classNames: ButtonClassNames,
      defaultProps: {size: 'md'},
      // vars: (theme, props) => ({
      //   root: {
      //     '--button-padding-x-lg': 'calc(0.625rem* var(--mantine-scale))',
      //     '--button-padding-x-md': 'calc(0.875rem* var(--mantine-scale))',
      //     '--button-padding-x-sm': 'calc(1.125rem* var(--mantine-scale))',
      //     '--button-padding-x-xl': 'calc(1.375rem* var(--mantine-scale))',
      //     '--button-padding-x-xs': 'calc(1.625rem* var(--mantine-scale))',
      //   },
      // }),
    }),
    Checkbox: Checkbox.extend({
      defaultProps: {size: 'md'},
    }),
    Input: Input.extend({
      classNames: InputClassNames,
      defaultProps: {size: 'md'},
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {size: 'md'},
    }),
    Overlay: Overlay.extend({classNames: OverlayClassNames}),
    Select: Select.extend({
      defaultProps: {size: 'md'},
    }),
    Switch: Switch.extend({
      defaultProps: {size: 'md'},
    }),
    TextInput: TextInput.extend({
      defaultProps: {size: 'md'},
    }),
  },
  defaultRadius: 6,
  focusRing: 'never',
  fontFamily,
  primaryColor: 'blue',
});
