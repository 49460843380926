import {MantineProvider} from '@mantine/core';
import {Notifications} from '@mantine/notifications';
import {type LoaderFunctionArgs, type LinksFunction, type MetaFunction} from '@remix-run/node'; // Depends on the runtime you choose
import {Links, Meta, Outlet, Scripts, useLoaderData, useLocation, useMatches} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import type React from 'react';
import {useEffect} from 'react';
import {AdminLayout} from '#/app/components/admin/AdminLayout.js';
import {DefaultLayout} from '#/app/components/admin/DefaultLayout.js';
import {ErrorBoundary as ErrorBoundaryComponent} from '#/app/components/admin/ErrorBoundary.tsx';
import {env} from '#/app/env.server.ts';
import {requireAdminContext} from '#/app/helpers/Utils/requireAdminContext.server.ts';
import stylesheet from '#/app/styles.css?url';
import {theme as theme2} from '#/app/theme/theme.js';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

export const meta: MetaFunction = () => {
  return [
    {charSet: 'utf-8'},
    {title: 'KC Kosher Coop - Administration'},
    {content: 'width=device-width, initial-scale=1', name: 'viewport'},
  ];
};

export const links: LinksFunction = () => {
  return [
    {href: stylesheet, rel: 'stylesheet'},
    {href: 'https://fonts.googleapis.com', rel: 'preconnect'},
    {href: 'https://fonts.gstatic.com', rel: 'preconnect'},
    {
      href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap',
      rel: 'stylesheet',
    },
  ];
};

interface DocumentProps {
  children: React.ReactNode;
  title?: string;
}

const Document = ({children, title}: DocumentProps) => {
  const {pathname} = useLocation();
  const Layout = pathname.indexOf('/admin') === 0 ? AdminLayout : DefaultLayout;

  return (
    <html lang="en" suppressHydrationWarning>
      <head>
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
        {/* <script
          crossOrigin="anonymous"
          src="//unpkg.com/react-scan/dist/auto.global.js"
        /> */}
      </head>
      <body>
        <Layout>{children}</Layout>
        <Scripts />
      </body>
    </html>
  );
};

export function ErrorBoundary() {
  return <ErrorBoundaryComponent isRoot={true} />;
}

export async function loader(args: LoaderFunctionArgs) {
  const context = await requireAdminContext(args);

  return Response.json({
    env: {
      COMMIT_SHA: env.COMMIT_SHA,
      NODE_ENV: env.NODE_ENV,
      SENTRY_DSN: env.SENTRY_DSN,
      // SENTRY_NOTIFY: env.SENTRY_NOTIFY,
    },
    viewer: context.viewer,
  });
};

export default function App() {
  const {env, viewer} = useLoaderData<typeof loader>();

  useEffect(() => {
    const isSentryInitialized = !!Sentry.getClient();
    if (!isSentryInitialized) {
      Sentry.init({
      // environment: ENV.RELEASE_STAGE,
        dsn: env.SENTRY_DSN,
        enabled: env.NODE_ENV === 'production',
        integrations: [
          Sentry.browserTracingIntegration({useEffect, useLocation, useMatches}),
          Sentry.replayIntegration({
            blockAllMedia: true,
            maskAllText: true,
          }),
        ],
        release: env.COMMIT_SHA,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        tracesSampleRate: 1.0,
      });
    }

    Sentry.setUser(viewer.id ? viewer : null);
  }, [env.COMMIT_SHA, env.NODE_ENV, env.SENTRY_DSN, viewer]);

  return (
    <Document>
      <MantineProvider theme={theme2}>
        <Notifications limit={10} />
        <Outlet />
      </MantineProvider>
    </Document>
  );
}
